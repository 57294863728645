<template>
  <div class="agreements-page table-page__thead--sticky">
    <agreements-edit
      v-if="isEdit && currentOrganization"
      @hide="isEdit = false"
      @save="refreshTable"
      :id="editId"
      :organization-id="currentOrganization.id"
    />

    <small
      class="text-xs font-bold leading-none tracking-widest text-gray-700 uppercase mb-2"
      >Admin</small
    >

    <page-header-tabs
      :tabs="tabs"
      :current-tab="currentTab"
      create-label="Add agreement"
      @create="create"
    >
      <multiselect
        v-model="currentOrganization"
        :options="organizations"
        track-by="id"
        label="name"
        class="w__220 mr-2"
      />
      <button class="btn" :disabled="!hasChanges" @click="saveChanged">
        Save
      </button>
    </page-header-tabs>

    <div class="mt-6">
      <card class="" body-class="p-0 pl-0 pr-0">
        <v-table
          v-if="currentOrganization"
          :columns="columns"
          :tools="false"
          :editable="true"
          row-key="id"
          :ref="tableRef"
          :endpoint="endpoint"
          :filter-params="{ 'filter[organization_id]': currentOrganization.id }"
          sort-key="priority"
          :sort-order="1"
          no-cache
          static
          class="mb-2"
          paginate
          query-params
          :on-rows-loaded="onRowsLoaded"
          :on-draggable-rows-set="onDraggableRowsSet"
          @draggable-row-change="onDraggableRowChange"
        >
          <template slot="cell" slot-scope="{ row, col }">
            <span v-if="dateFields.includes(col.key)">
              {{ formatDate(row[col.key]) }}
            </span>
            <div
              v-else-if="col.key === 'organization_name'"
              :key="`wrapper_${col.key}`"
              :class="col.width"
            >
              {{ row.organization.name }}
            </div>
            <div
              v-else-if="col.key === 'payer'"
              :key="`wrapper_${col.key}`"
              :class="col.width"
            >
              <multiselect
                v-model="row[col.key]"
                :options="payerOptions"
                open-direction="bottom"
                @select="updateHasChanges"
              />
            </div>
            <div v-else-if="col.key === 'carrier_name'">
              <multiselect
                :value="row.carrier"
                :options="carrierOptions"
                @select="updateField({ row, key: col.key, value: $event })"
              />
            </div>
            <div v-else-if="col.key === 'carrier_service_name'">
              {{ row[col.key] }}
              <multiselect
                v-if="row.carrier"
                v-model="row.carrier_service"
                :options="carrierServiceOptions[row.carrier] || []"
                @select="updateHasChanges"
              />
            </div>
            <div v-else-if="getIsAutocompleteField(col.key)">
              <autocomplete
                :key="`autocomplete_${col.key}_index`"
                v-model="row[col.key]"
                :endpoint="
                  filterOptionsUrl({
                    role: col.key.split('_')[0],
                    field: col.key.split('_')[1],
                  })
                "
                :search-trigger-chars-threshold="2"
                @click="updateField({ row, key: col.key, value: $event })"
              />
            </div>
            <div v-else-if="col.key === 'is_daily_pickup'">
              <v-checkbox v-model="row[col.key]" @click="updateHasChanges" />
            </div>
            <div v-else-if="col.key === 'actions'">
              <button class="ml-2" @click="confirmRemoval(row.id)">
                <svg-importer icon-name="icons/close" />
              </button>
            </div>
            <div v-else-if="col.key === 'priority'" :class="col.width">
              <button class="handle mr-2" @mousedown="isDraggingId = row.id">
                  <fa :icon="['fal','grip-lines']" style="width: 20px; height: 20px;" />
              </button>
                  {{ row[col.key] }}
            </div>
            <div v-else :class="col.width">
              <input
                :key="`input_${col.key}_index`"
                type="text"
                :value="row[col.key]"
                placeholder="Enter value"
                @blur="
                  updateField({ row, key: col.key, value: $event.target.value })
                "
              />
            </div>
          </template>
        </v-table>
      </card>
    </div>
    <delete-modal
        :show="showDeleteModal"
        @hide="closeDeleteModal"
        name="Agreement"
        :selectedObject="selectedAgreement"
        @delete="deleteAgreement"
    />
  </div>
</template>

<script>
import axios from "axios";

import { requestErrors } from "~/utils/errors";

import PageHeaderTabs from "~/components/PageHeaderTabs";
import AgreementsEdit from "./AgreementsEdit.vue";
import Autocomplete from "~/components/Autocomplete";
import VCheckbox from "~/components/VCheckbox";
import Multiselect from "vue-multiselect";
import DraggablePriorityMixin from '~/mixins/draggablePriority.mixin.js';

const AUTOCOMPLETE_FIELD_NAMES = [
  "consignor_name",
  "consignee_name",
  "pickup_city",
];
const PAYLOAD_IGNORE_FIELDS = ["id", "updated_at", "created_at"];
const COLUMNS = [
  {
    title: "Priority",
    key: "priority",
    toggled: true,
    sortable: false,
    width: "w-24",
  },
  {
    title: "Organization",
    key: "organization_name",
    toggled: true,
    sortable: false,
    width: "w-40",
  },
  {
    title: "Carrier",
    key: "carrier_name",
    toggled: true,
    sortable: true,
    width: "w-40",
  },
  {
    title: "Service",
    key: "carrier_service_name",
    toggled: true,
    sortable: true,
    width: "w-40",
  },
  {
    title: "Payer",
    key: "payer",
    toggled: true,
    sortable: true,
    width: "w-40",
  },
  {
    title: "Consignee name",
    key: "consignee_name",
    toggled: true,
    sortable: true,
    width: "w-40",
  },
  {
    title: "Consignor name",
    key: "consignor_name",
    toggled: true,
    sortable: true,
    width: "w-40",
  },
  {
    title: "Delivery country",
    key: "delivery_country_code",
    toggled: true,
    sortable: true,
    width: "w-40",
  },
  {
    title: "Pickup country",
    key: "pickup_country_code",
    toggled: true,
    sortable: true,
    width: "w-40",
  },
  {
    title: "Pickup city",
    key: "pickup_city",
    toggled: true,
    sortable: true,
    width: "w-40",
  },
  {
    title: "Consignor agreement no",
    key: "consignor_agreement_no",
    toggled: true,
    sortable: true,
    width: "w-40",
  },
  {
    title: "Consignor agreement name",
    key: "consignor_agreement_name",
    toggled: true,
    sortable: true,
    width: "w-40",
  },
  {
    title: "Consignee agreement no",
    key: "consignee_agreement_no",
    toggled: true,
    sortable: true,
    width: "w-40",
  },
  {
    title: "Consignee agreement name",
    key: "consignee_agreement_name",
    toggled: true,
    sortable: true,
    width: "w-40",
  },
  {
    title: "Unifaun ID",
    key: "unifaun_id",
    toggled: true,
    sortable: false,
    width: "w-40",
  },
  {
    title: "Is daily pickup",
    key: "is_daily_pickup",
    toggled: true,
    sortable: true,
    width: "w-40",
  },
  {
    title: "",
    key: "actions",
    toggled: true,
    sortable: false,
    width: "w-32",
  },
];

const preparePayloadRow = (row, carriers) => {
  const rule = {};

  Object.entries(row).forEach(([key, value]) => {
    if (PAYLOAD_IGNORE_FIELDS.includes(key)) {
      return;
    }

    if (key === "priority") {
      rule[key] = +row[key];

      return;
    }

    if (key === 'carrier') {
      rule.carrier_id = carriers.find(data => {
        return data.carrier.name === value
      })?.carrier?.id;

      return;
    }

    if (key === 'carrier_service') {
      carriers.find(
        data => {
          const service = data.services.find(service => service.name === value);

          if (service) {
            rule.carrier_service_id = service.id;
          }

          return service;
        }
      );

      return;
    }

    if (key === 'organization') {
      rule.organization_id = row.organization.id;

      return;
    }

    rule[key] = key === "is_daily_pickup" ? value : value || "*";

    if (key === 'unifaun_id' && !value) {
      rule[key] = null;
    }
  });

  return rule;
};

export default {
  name: "BookingAgreements",

  mixins: [DraggablePriorityMixin],

  components: {
    PageHeaderTabs,
    AgreementsEdit,
    Autocomplete,
    VCheckbox,
    Multiselect,
  },

  watch: {
    async currentOrganization() {
      await this.$nextTick();
      this.$refs[this.tableRef]?.table.fetchRows();
    },
  },

  data() {
    return {
      tabs: {
        "0": {
          value: "Agreements settings",
          routeName: "/admin/booking/agreements",
        },
      },

      currentTab: "0",
      isEdit: false,
      editId: null,
      endpoint: this.$apiUrl.booking.base,
      columns: COLUMNS,
      tableRef: "booking-agreements",
      hasChanges: false,
      initialRows: [],
      dateFields: ["updated_at", "created_at"],
      payerOptions: ["consignor", "consignee", "third party"],
      carriers: [],
      carrierOptions: [],
      carrierServiceOptions: {},
      selectedAgreement: null,
      showDeleteModal: false,
      organizations: [],
      currentOrganization: null,
      isLoading: false,
      isLoadingNew: false,
      isDraggingId: null,
    };
  },

  computed: {
    user() {
      return this.$store.getters['auth/user'];
    },
  },

  methods: {
    filterOptionsUrl({ role, field }) {
      return (value) => {
        return (
          this.$apiUrl.addressBook.filterOptions +
          `?filter[organization_id]=${this.organization_id.id}` +
          `&field=${field}` +
          `&role=${role}` +
          `&value=${value}`
        );
      };
    },
    getIsAutocompleteField(fieldName) {
      return AUTOCOMPLETE_FIELD_NAMES.find(
        (name) => fieldName.indexOf(name) !== -1
      );
    },
    updateField({ row, key, value }) {
      row[key] = value;

      if (key === "carrier") {
        row.carrier_service = null;
      }

      this.updateHasChanges();
    },
    async updateHasChanges() {
      await this.$nextTick();
      this.hasChanges = !!this.$refs[this.tableRef].table.rows.find(
        (tableRow) => {
          const match = this.initialRows.find(
            (initialRow) => tableRow.id === initialRow.id
          );

          return Object.keys(tableRow).find(
            (key) =>
              JSON.stringify(tableRow[key]) !== JSON.stringify(match[key])
          );
        }
      );
    },
    refreshTable() {
      this.$refs[this.tableRef].table.fetchRows();
    },

    onRowsLoaded(rows) {
      rows.forEach(
        (row) => {
          row.is_daily_pickup = Number(row.is_daily_pickup);
          row.carrier = row.carrier?.name || '';
          row.carrier_service = row.carrier_service?.name || '';
        }
      );

      this.initialRows = JSON.parse(JSON.stringify(rows));
      this.rowsLength = rows.length;
      this.hasChanges = false;
    },

    create() {
      (this.editId = null), (this.isEdit = true);
    },

    edit({ id }) {
      (this.editId = id), (this.isEdit = true);
    },

    getChangedRows() {
      const changedRows = this.$refs[this.tableRef].table.rows.filter(
        (tableRow) => {
          const match = this.initialRows.find(
            (initialRow) => tableRow.id === initialRow.id
          );

          return Object.keys(tableRow).filter(
            (key) =>
              JSON.stringify(tableRow[key]) !== JSON.stringify(match[key])
          ).length;
        }
      );

      return changedRows;
    },

    preparePayload(row) {
      if (!row) {
        return;
      }

      return { id: row.id, payload: preparePayloadRow(row, this.carriers) };
    },

    async saveChanged() {
      const changedRows = this.getChangedRows();

      if (!changedRows.length) {
        this.$snotify.error("No agreements to update");

        return;
      }

      const promises = [];
      changedRows.forEach((row) => {
        const { id, payload } = this.preparePayload(row);
        promises.push(axios.post(this.$apiUrl.booking.id(id), payload));
      });

      try {
        const result = await Promise.allSettled(promises);

        // catch first rejected promise error
        const rejected = result.find(promise => promise.status === 'rejected');
        if (rejected) {
          throw rejected.reason;
        }

        this.$refs[this.tableRef].table?.fetchRowsSilent();
        this.hasNewRules = false;

        this.$snotify.success("Rule(s) updated");
      } catch (error) {
        this.$snotify.error(requestErrors(error));
      }
    },

    confirmRemoval(id) {
      this.showDeleteModal = true;
      this.selectedAgreement = { id };
    },

    closeDeleteModal() {
      this.showDeleteModal = false;
      this.selectedAgreement = null;
    },

    async deleteAgreement() {
      this.showDeleteModal = false;

      try {
        await axios.delete(this.$apiUrl.booking.id(this.selectedAgreement.id));

        this.$refs[this.tableRef]?.table.fetchRowsSilent();

        this.$snotify.success('Agreement has been removed');
      } catch(error) {
        this.$snotify.error(requestErrors(error));
      }

      this.selectedAgreement = null;
    },

    async fetchCarrierList() {
      const {
        data: {
          data: {
            carrier_data: { filtered_carriers },
          },
        },
      } = await axios.get("/api/filter-options?filter[date_between]=today");

      this.carriers = filtered_carriers;
      this.carrierOptions = filtered_carriers.map((item) => {
        this.carrierServiceOptions[item.carrier.name] = item.services.map(service => service.name);

        return item.carrier.name;
      });
    },

    async fetchOrganizations() {
      const { data: { data } } = await axios.get(this.$apiUrl.organizations.base + '?pageSize=1000');

      this.organizations = data.map(item => ({ id: item.id, name: item.name }));
      this.currentOrganization = this.organizations.find(org => org.id === this.user.organization.id);
    },
  },

  async created() {
    this.fetchOrganizations();
    this.fetchCarrierList();
  },
};
</script>
<style lang="scss">
.agreements-page {
  .table-wrapper {
    max-height: calc(100vh - 190px);
    overflow: auto;
    padding-bottom: 234px;

    td.truncate {
      overflow: visible;
    }

    .autocomplete {
      input {
        margin-top: 0;
      }
    }
  }
}
</style>
